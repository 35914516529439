export const BASE_URL = 'https://gateway.fikfis.co.uk/';

export const REGISTER_USER_BASE_CONSTANT = 'registerUser';
export const REGISTER_USER_OTP_BASE_CONSTANT = 'registerUserOtp';
export const LOGIN_USER_BASE_CONSTANT = 'login';
export const FORGET_PASSWORD_REQUEST_CONSTANT = 'forgotPasswordRequest';
export const FORGET_PASSWORD_OTP_REQUEST_CONSTANT = 'otpValidation';
export const FORGET_PASSWORD_CONFIRM_REQUEST_CONSTANT = 'forgotPassword';
export const LOGOUT_REQUEST_CONSTANT = 'logout';
export const GET_USER_PROFILE_CONSTANT = 'getProfile';
export const CHANGE_PASSWORD_CONSTANT = 'changePassword';
export const UPDATE_PROFILE_CONSTANT = 'updateProfile';
export const CMSP_PAGE_CONSTANT = 'cms/getPage';
export const LIST_ADDRESS_CONSTANT = 'listAddress';
export const HOME_DATA_CONSTANT = 'homeData';
export const HOME_SECTION_CONSTANT = 'homeSection';
export const ALL_CATEGORIES_CONSTANT = 'categories';
export const SUB_CATEGORIES_CONSTANT = 'category';
export const RECENT_VIEW_CONSTANT = 'recentlyViewed';
export const PRODUCTS_CONSTANT = 'product';
export const PRODUCTS_LIST_CONSTANT = 'products';
export const SEARCH_PRODUCT_CONSTANT = 'searchProduct';
export const JWT_TOKEN_CONSTANT = 'jwtViaRefToken';

export const fcm_token = "AxtyAdkasadad565asd";
export const device_token = "60f0d009a0d53777";

// CMS Constant
export const TERMS_CONDITION = 'terms-and-conditions';
export const PRIVACY_POICY = 'privacy-policy';
export const DISCLAIMER = 'disclaimer';
export const REFUND_POLICY = 'refund-policy';
export const RETURN_REFUND = 'returns--refunds';
export const SHIPPING_DELIVERY = 'shipping--delivery';
export const ORDER_CANCELLATION = 'order-cancellation';
export const ABOUT_US = 'about-us';
export const FAQS = 'faqs';

// Message Constant
export const PASSWORD = "Password must be at least 8 characters long, contain one uppercase letter, one lowercase letter, one number, and one special character."
export const EMAIL_OR_MOBILE = "Please enter a valid email address or mobile number.";
export const PASSWORD_NOT_MATCH_ERROR = "Confirm Password not matched.";
export const VALID_OTP = "Please enter a valid 6-digit OTP.";

export const EMAIL_OR_MOBILE_LABEL = "Email or mobile phone number";
export const EMAIL_LABEL = "userPhoneOrEmail";
export const PASSWORD_LABEL = "Password";
export const PASSWORD_TYPE = "password";
export const OLD_PASSWORD_LABEL = "Old Password";
export const NEW_PASSWORD_LABEL = "New Password";
export const OLD_PASSWORD_ENTER = "Enter old password";
export const NEW_PASSWORD_ENTER = "Enter new password";
export const OLD_PASSWORD = "oldPassword";
export const NEW_PASSWORD = "newPassword";
export const CONFIRM_PASSWORD_LABEL = "Confirm Password";
export const CONFIRM_PASSWORD = "confirmPassword";
export const PASSWORD_NOT_MATCH_MESSAGE = "Passwords do not match.";
export const TEMP_CODE_LABEL = "Temporary code (OTP)";
export const TEMP_CODE_PLACEHOLDER = "Enter a temporary code";
export const OTP_NAME = "otpCode";
export const OTP_ERROR = "Please enter a valid 6-digit OTP";